.Viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Viewer-title {
  margin-top: 0;
  margin-bottom: var(--spacing-small);
  font-size: 1.2rem;
}

.Viewer-loader {
  height: 100%;
}

.Viewer-loader > img {
  width: 10rem;
}

.Viewer-inner {
  overflow: auto;
  margin-bottom: var(--spacing);
  flex-grow: 1;
}

.Viewer-edit-link {
  background-color: var(--color-blue);
}

.Viewer-content.markdown-container {
  overflow: hidden;
}

.Viewer-content.markdown-container pre {
  overflow: auto;
}
