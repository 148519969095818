.Edit {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Edit-loader {
  height: 100%;
}

.Edit-loader > img {
  width: 10rem;
}

.Edit-actions {
  display: flex;
  justify-content: flex-end;
}

.Edit-delete-button {
  background-color: var(--color-gray-dark);
  color: white;
}

.Edit-delete-button:focus {
  box-shadow: inset 0 0 0 var(--spacing-xsmall) rgba(255, 255, 255, 0.3);
}

.Edit-actions > * + * {
  margin-left: var(--spacing);
}
