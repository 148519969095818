.Header-logo {
  height: 3rem;
}

.Header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: var(--spacing-xsmall) var(--spacing-small);

  font-size: 1rem;
  background-color: var(--color-gray-dark);
  color: white;
}

.Header h1 {
  flex-grow: 1;
  margin: 0;

  text-align: right;
}

.Header h1 a {
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-small);
  padding-left: 0;
  font-size: 1.6rem;

  text-decoration: none;
  color: inherit;
}

.Header h1 a:hover {
  filter: contrast(175%);
}

.Header h1 a:focus {
  box-shadow: inset 0 0 0 var(--spacing-xsmall) var(--color-blue);
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Header h1 a:hover:not(:focus) .Header-logo {
    animation: spin infinite 20s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
