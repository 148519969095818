.ListItem-link {
  justify-content: flex-start;
  background-color: transparent;
}

.ListItem-link:hover {
  background-color: var(--color-blue);
  filter: none;
}

.ListItem-link:focus {
  box-shadow: inset 0 0 0 var(--spacing-xsmall) rgba(0, 0, 0, 0.1);
  outline: none;
}

.ListItem-link.ListItem-link-active {
  background-color: var(--color-gray-dark);
  color: white;
}

.ListItem-link.ListItem-link-active:hover {
  color: var(--color-blue);
}

.ListItem-link.ListItem-link-active:focus {
  box-shadow: inset 0 0 0 var(--spacing-xsmall) rgba(255, 255, 255, 0.3);
}
