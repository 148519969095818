.Form {
  flex-grow: 1;
}

.Form-fieldset {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 0;
  margin: 0;
  margin-bottom: var(--spacing);
}

.Form-label {
  margin-bottom: var(--spacing-xsmall);
}

.Form-input {
  padding: var(--spacing-xsmall);
  min-height: var(--spacing-large);
  width: 100%;
  border: 1px solid var(--color-gray-dark);
}

.Form-textarea {
  resize: vertical;
}

.Form-actions {
  display: flex;
  justify-content: space-between;
}

.Form-actions > * {
  font-size: 1rem;
  line-height: 1rem;
}

.Form-cancel-link {
  background-color: white;
}

.Form-cancel-link:hover,
.Form-cancel-link:focus {
  box-shadow: inset 0 0 0 var(--spacing-xsmall) rgba(0, 0, 0, 0.1);
  outline: none;
  filter: none;
}
