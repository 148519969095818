body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: var(--color-gray-dark);
  background-color: white;
}

* {
  box-sizing: border-box;
}

:root {
  --color-blue: #61dafb;
  --color-gray-light: #eee;
  --color-gray-dark: #282c34;

  --spacing-xsmall: 0.333rem;
  --spacing-small: 0.666rem;
  --spacing: 1rem;
  --spacing-large: 1.333rem;
  --spacing-xlarge: 1.666rem;
}

#root {
  height: 100%;
}
