.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-main {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing);
}

.App-main section {
  overflow: auto;
  flex-grow: 1;
  padding: var(--spacing-small);
  width: 100%;
  background-color: var(--color-gray-light);
}

.App-main section + section {
  margin-left: var(--spacing);
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .App-main {
    display: block;
    max-height: 100%;
    overflow: auto;
  }

  .App-main section {
    max-height: 50%;
    flex-grow: 0;
    justify-content: none;
  }

  .App-main section + section {
    max-height: initial;
    margin-left: 0;
    margin-top: var(--spacing);
  }
}
