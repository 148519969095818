.List {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.List-loader {
  height: 100%;
}

.List-loader img {
  width: 10rem;
}
